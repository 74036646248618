<template>
  <div class="popup-form">
    <div class="form">
      <div :class="{error:error}">
        <spinner v-if="sending" />
        <div>
          <div
            v-if="!(showLogin || showForm !== 'login')"
            class="header"
          >
            <div class="tab">
              <ul>
                <li
                  id="list_registration"
                  :class="{active:form==='register'}"
                  @click="form='register'"
                >
                  Регистрация
                </li>
                <li
                  id="list_authorization"
                  :class="{active:form==='login' || form==='remember'}"
                  @click="form='login'"
                >
                  Войти
                </li>
              </ul>
            </div>
          </div>
          <img
            id="closing_cross"
            src="/img/closebtn.svg"
            class="close"
            @click="$emit('close')"
          >
          <div class="body">
            <div v-if="!(showLogin || showForm !== 'login')">
              <register
                v-if="form==='register'"
                @register="register"
                @send="sending = true"
                @done="sending = false"
                @error="shaking"
                @close="close"
              />
              <login
                v-if="form==='login'"
                @login="login"
                @remember="form='remember'"
                @send="sending = true"
                @done="sending = false"
                @error="shaking"
              />
              <remember
                v-if="form==='remember'"
                @submitRemember="submitRemember"
                @send="sending = true"
                @done="sending = false"
                @error="shaking"
              />
            </div>
            <div v-else>
              <lidform
                @send="sending = true"
                @done="sending = false"
                @error="shaking"
                @close="close"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from '@/components/common/theSpinner.vue';
import register from '@/components/auth/theRegister.vue';
import lidform from '@/components/auth/lidForm.vue';
import login from '@/components/auth/theLogin.vue';
import remember from '@/components/auth/theRemember.vue';

/**
 * @deprecated
 */
export default {
  name: 'RegisterForm',
  components: {
    register,
    login,
    remember,
    lidform,
    Spinner,
  },
  props: {
    showForm: {
      type: String,
      default: 'register',
    },
  },
  data() {
    return {
      form: this.showForm,
      sending: false,
      error: false,
      showLogin: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  mounted() {
    window.addEventListener('keydown', this.keyDownHnd);
    this.showLogin = this.user;
  },
  beforeMount() {
    window.removeEventListener('keydown', this.keyDownHnd);
  },
  methods: {
    shaking() {
      this.error = true;
      window.setTimeout(() => {
        this.error = false;
      }, 500);
    },
    login(user) {
      this.$emit('login', user);
    },
    register(payload) {
      this.$emit('register', payload);
    },
    submitRemember(payload) {
      this.$emit('submitRemember', payload);
    },
    keyDownHnd(e) {
      if (e.key === 'Escape') {
        this.close();
      }
    },
    close() {
      this.$emit('close');
    },
    // showLoginForm() {
    //   this.showLogin = true;
    // },
  },
};
</script>

<style scoped lang="scss">
.popup-form .tab ul li {
  font-size: 20px;
  @include respond-to(allmobile) {
    font-size: 16px;
  }
}

.form {
  position: absolute;
  left: 50%;
  top: 10vh;
  transform: translateX(-50%);
  background-color: $white-color;
  border-radius: 24px;
  padding: 32px 44px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 450px;
  width: 450px;
  box-sizing: border-box;
  animation-name: slideInDown, fadeIn;
  animation-duration: .4s;
  animation-fill-mode: both;
  //overflow: hidden;

  @include respond-to(mobile) {
    width: 90%;
    padding: 2em 20px;
  }

  @include respond-to(tablet) {
    width: 70%;
    padding: 2em 70px;
  }
}

.popup-form {
  position: fixed;
  overflow-y: auto;

  animation-name: fadeIn;
  animation-duration: .4s;
  animation-fill-mode: both;

  &::-webkit-scrollbar {
    width: 0;
  }

  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 100;
  font-size: 1.1em;
  backdrop-filter: blur(2px);

  .error {
    position: relative;
    animation: 500ms shaking;

    .close {
      right: -1.75em;
      top: -1em;
    }
  }

  .tab {
    ul {
      display: flex;
      justify-content: space-around;
      //padding-bottom:1.5em;
      border-bottom: 1px solid rgba($color: $gray-color, $alpha: .5);

      @include respond-to(mobile) {
        justify-content: flex-start;
      }

      li {
        cursor: pointer;
        position: relative;
        color: $gray-color;
        padding: 0.5em 1.5em 1em 1.5em;

        &.active {
          color: $orange-color;
          font-weight: 500;
          cursor: default;

          &:after {
            content: '';
            position: absolute;
            display: block;
            width: 100%;
            bottom: 0;
            //transform: translateY(.5em);
            left: -2px;
            right: 0;
            height: 4px;
            background-color: $orange-color;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
          }
        }

        @include respond-to(mobile) {
          margin-right: 2em;
        }
      }
    }

  }

  .close {
    position: absolute;
    right: 1em;
    top: 1em;
    cursor: pointer;
    transition: transform ease .2s;

    &:hover {
      transform: scale(1.2);
    }
  }
}

</style>
