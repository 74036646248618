<template>
  <div :class="fieldset_name_classes">
    <input
      v-model="$v.name.$model"
      placeholder="Имя"
      type="text"
      name="name"
    >
    <img
      v-if="$v.name.$error"
      alt="error"
      src="/img/error.svg"
      class="error"
    >
    <div
      v-if="is_name_required_error"
      class="error-message"
      v-text="errors.is_name_required_error"
    />
    <div
      v-else-if="is_only_chars_error"
      class="error-message"
      v-text="errors.is_only_chars_error"
    />
    <div
      v-else-if="is_name_max_error"
      class="error-message"
      v-text="errors.is_name_max_error"
    />
  </div>
</template>

<script>
/**
 * Mixins
 */
import { validationMixin } from 'vuelidate';

/**
 * Validations
 */
import { required, maxLength } from 'vuelidate/lib/validators';
import { isOnlyChars } from '@/components/validations';

/**
 * Имя пользователя. Компонент включает в себя:
 *  - Поле ввода;
 *  - Валидацию;
 *  - Сообщения об ошибках.
 */
export default {
  name: 'NameRegistrationField',

  mixins: [
    validationMixin,
  ],

  props: {
    /**
     * Имя
     */
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      name: this.value,

      /**
       * Ошибки для пользователя
       */
      errors: {
        is_name_required_error: 'Укажите свое имя',
        is_only_chars_error: 'Имя может содержать только буквы',
        is_name_max_error: 'Слишком длинное имя',
      },
    };
  },

  computed: {
    isValid() {
      return !this.$v.name.$invalid;
    },

    fieldset_name_classes() {
      return {
        fieldset: true,
        'has-error': this.$v.name.$error,
      };
    },

    is_name_required_error() {
      return !this.$v.name.required && this.$v.name.$error;
    },

    is_only_chars_error() {
      return !this.$v.name.onlyChars;
    },

    is_name_max_error() {
      return !this.$v.name.maxLength && this.$v.name.$error;
    },
  },

  watch: {
    value(value) {
      this.name = value;
    },

    name(value) {
      this.$emit('input', value);
    },
  },

  methods: {
    validate() {
      this.$v.$touch();
    },
  },

  validations: {
    name: {
      required,
      onlyChars() {
        return isOnlyChars(this.name);
      },
      maxLength: maxLength(30),
    },
  },
};
</script>

<style lang="scss" scoped>

.error-message-all,
.error-message {
  font-size: 0.8em;
  color: #eea2a2;
}

.error-message {
  position: absolute;
  top: 0;
  padding: 1em;
  background-color: $white-color;
  border-radius: 10px;
  right: 0;
  transform: translateY(calc(-100% - 10px)) translateX(70%);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
  width: 15em;
  text-align: left;
  transition: 500ms;

  @include respond-to(allmobile) {
    right: 10em;
  }

  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 13px 13px 0 13px;
    border-color: $white-color transparent transparent transparent;
    bottom: 0;
    left: calc(20% - 1em);
    transform: translateY(calc(100% - 1px));

    @include respond-to(allmobile) {
      left: calc(80% - 1em);
    }
  }
}

.has-error input:hover + .error-message.error,
.has-error input:focus + .error-message {
  opacity: 1;
}

.fieldset.has-error {
  position: relative;
}

img.error {
  position: absolute;
  display: block;
  right: 0.8em;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

/*
  @TODO Вынести стили глобально
 */
.popup-form .agree .checkbox.checked:after {
  border-bottom: 3px solid $purple-color;
  border-left: 3px solid $purple-color;
}

.subtitle {
  font-size: 16px;
  line-height: 134%;
  text-align: center;
  color: $grey-select;
}

.title {
  font-size: 24px;
  line-height: 134%;
  text-align: center;
  color: $grey;
}

.btn {
  font-size: 20px;
}

input[type=text], input[type=email], input[type=password] {
  border-radius: 1em;
  // margin: 0;
  display: block;
  width: 100%;
  outline: none;
  box-sizing: border-box;
  padding: 0.7em 1em;
  font-size: 20px;
  height: 60px;
}
</style>
