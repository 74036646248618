<template>
  <div class="fields center">
    <div
      class="fieldset"
      :class="{'has-error': $v.email.$error || submitError}"
    >
      <input
        v-model="$v.email.$model"
        type="email"
        placeholder="E-mail"
        @input="submitError = false"
      >
      <div
        v-if="$v.email.required && $v.email.$error"
        class="error-message"
      >
        Укажите свой Email
      </div>
      <div
        v-if="! $v.email.email && $v.email.$error"
        class="error-message"
      >
        Укажите правильный Email
      </div>
      <div
        v-if="submitError"
        class="error-message"
      >
        Email не найден
      </div>
    </div>
    <button
      class="btn"
      :disabled="!valid"
      @click="submit"
    >
      Восстановить
    </button>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';

const { required, email } = require('vuelidate/lib/validators');

/**
 * @deprecated
 */
export default {
  name: 'TheRemember',
  mixins: [validationMixin],
  data() {
    return {
      email: '',
      submitError: false,
    };
  },
  computed: {
    valid() {
      return !this.$v.$invalid;
    },
  },
  methods: {
    submit() {
      this.$api.remember(this.email)
        .then((response) => {
          const data = response.data?.forgotPassword;
          if (!data.success) {
            this.$emit('error');
            this.submitError = true;
          } else {
            this.$emit('submitRemember', { email: this.email });
          }
        })
        .catch(() => {
          this.$emit('error');
        })
        .finally(() => {
          this.$emit('done');
        });
    },
  },
  validations: {
    email: {
      required,
      email,
    },
  },
};
</script>

<style lang="scss" scoped>

input[type='text'],
input[type='email'],
input[type='password'] {
  border-radius: 1em;
  margin: 1em 0;
  display: block;
  width: 100%;
  outline: none;
  box-sizing: border-box;
  padding: .7em 1em;
  font-size: 20px;
  height: 60px;
}

.btn {
  width: 100%;
  height: 60px;

}

.error-message-all,
.error-message {
  font-size: 0.8em;
  color: #eea2a2;
}

.error-message {
  position: absolute;
  top: 0;
  padding: 1em;
  background-color: $white-color;
  border-radius: 10px;
  right: 0;
  transform: translateY(calc(-100% - 10px)) translateX(70%);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
  width: 15em;
  text-align: left;
  transition: 500ms;

  @include respond-to(allmobile) {
    right: 10em;
  }

  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 13px 13px 0 13px;
    border-color: $white-color transparent transparent transparent;
    bottom: 0;
    left: calc(20% - 1em);
    transform: translateY(calc(100% - 1px));

    @include respond-to(allmobile) {
      left: calc(80% - 1em);

    }
  }
}

.has-error input:hover + .error-message.error,
.has-error input:focus + .error-message {
  opacity: 1;
}

.fieldset.has-error {
  position: relative;

  &::after {
    position: absolute;
    content: url("/img/error.svg");
    display: block;
    right: 0.8em;
    top: 50%;
    transform: translateY(calc(-50% + 1px));

    &:hover .message {
      cursor: pointer;
    }
  }
}

.fields {
  animation-name: fadeInUp;
  animation-duration: .1s;
  animation-fill-mode: both;
}

</style>
