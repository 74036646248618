<template>
  <div class="popup-form">
    <div class="form register-done">
      <div class="title">
        {{ title }}
      </div>
      <img
        src="/img/closebtn.svg"
        class="close"
        @click="$emit('close')"
      >
      <div class="body">
        <div
          class="subtitle"
          v-html="message"
        />
        <img
          class="nosorog"
          :src="`/img/${image}`"
          alt="nosorog"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: 'Проверьте почту!',
    },
    image: {
      type: String,
      default: 'letter.svg',
    },
  },
};
</script>

<style lang="scss" scoped>
.popup-form {
  position: fixed;
  overflow-y: auto;

  animation-name: fadeIn;
  animation-duration: .4s;
  animation-fill-mode: both;

  &::-webkit-scrollbar {
    width: 0;
  }

  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 100;
  font-size: 1.1em;
  backdrop-filter: blur(2px);
}

.form {
  overflow: hidden;
  position: absolute;
  left: 50%;
  top: 10vh;
  transform: translateX(-50%);
  background-color: $white-color;
  border-radius: 24px;
  padding: 32px 55px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 450px;
  width: 450px;
  box-sizing: border-box;
  animation-name: slideInDown, fadeIn;
  animation-duration: .4s;
  animation-fill-mode: both;

  @include respond-to(mobile) {
    width: 90%;
    padding: 2em 20px;
  }

  @include respond-to(tablet) {
    width: 70%;
    padding: 2em 70px;
  }
}

.close {
  position: absolute;
  right: 1em;
  top: 1em;
  cursor: pointer;
  transition: transform ease .2s;

  &:hover {
    transform: scale(1.2);
  }
}

.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 134%;
  text-align: center;
  color: $grey;
}

.subtitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 134%;
  text-align: center;
  color: $grey-select;
  margin: 1em 0 0 0;
}

.nosorog {
  margin: 0 auto;
  display: block;
}
</style>
