<template>
  <footer class="main_footer">
    <cookies-popup />

    <div class="container">
      <div class="row between footer__content">
        <div class="column">
          <div class="column_logo">
            <nuxt-link to="/">
              <img
                class="column_logo_item"
                src="/img/full-logo.svg"
                alt="logo"
              >
            </nuxt-link>
            <hr>
            <a
              href="https://sk.ru/"
              target="_blank"
            >
              <img
                class="column_logo_item column_logo_item__mobhide column_logo_item__mobhide_last"
                src="/img/Sk_resident2ru.svg"
                alt="icon"
              >
            </a>
            <!--<img-->
            <!--  class="column_logo_item column_logo_item__mobhide"-->
            <!--  src="/img/fipi.svg"-->
            <!--  alt="fipi"-->
            <!--&gt;-->
          </div>
          <div class="footer__copyright">
            © {{ currentYear }} Университет «Синергия» Все права защищены
          </div>
        </div>
        <div
          v-show="user"
          class="column column_list_user column_margin"
        >
          <ul class="footer__list">
            <li class="footer__list-item">
              <a href="https://courses.synergy.ru/">Курсы Синергия</a>
            </li>
            <li class="footer__list-item">
              <a href="https://school.synergy.ru/">Школа Синергия</a>
            </li>
            <li class="footer__list-item">
              <a href="/classroom/feedback/">Обратная связь</a>
            </li>
          </ul>
        </div>
        <div
          v-show="user"
          class="column column_list_user column_list_user_last column_margin"
        >
          <ul class="footer__list">
            <li class="footer__list-item">
              <nuxt-link to="/privacy/">
                Политика конфиденциальности
              </nuxt-link>
            </li>
            <li class="footer__list-item">
              <nuxt-link to="/rekviziti/">
                Реквизиты
              </nuxt-link>
            </li>
            <!--
            <li
              v-if="user"
              class="footer__list-item"
            >
              <nuxt-link
                to="/classroom/webinar-test/"
                target="_blank"
              >
                Тест вебинара
              </nuxt-link>
            </li>
            -->
          </ul>
        </div>
        <div
          v-show="!user"
          class="column column_margin"
        >
          <ul class="footer__list">
            <li class="footer__list-item privacy">
              <nuxt-link to="/privacy/">
                Политика конфиденциальности
              </nuxt-link>
            </li>
            <li class="footer__list-item">
              <nuxt-link to="/rekviziti/">
                Реквизиты
              </nuxt-link>
            </li>
          </ul>
        </div>
        <div class="column contacts">
          <div class="contacts_wspphone">
            <img
              class="whatsappLink"
              src="/img/whatsapp.svg"
              alt="whatsapp"
              @click="whatsappLink"
            >
            <a
              class="footer__number"
              :href="`tel:${phone}`"
            >
              {{ phone | prettyPhone }}
            </a>
          </div>
          <a
            class="footer__email"
            href="mailto:online.school@synergy.ru"
          >
            online.school@synergy.ru
          </a>
          <div class="footer_social">
            <a
              href="https://dzen.ru/schoolsynergy"
              target="_blank"
              class="footer_social_item"
            >
              <img
                src="/img/social/zen.svg"
                alt="yzen"
              >
            </a>

            <a
              href="https://vk.com/synergyonlineschool"
              target="_blank"
              class="footer_social_item"
            >
              <img
                src="/img/social/vk.svg"
                alt="vk"
              >
            </a>

            <a
              href="https://t.me/synergyschool"
              target="_blank"
              class="footer_social_item"
            >
              <img
                src="/img/social/telegram.svg"
                alt="telegram"
              >
            </a>

            <a
              href="https://www.youtube.com/@schoolsynergy"
              target="_blank"
              class="footer_social_item"
            >
              <img
                src="/img/social/youtube.svg"
                alt="youtube"
              >
            </a>
          </div>
        </div>
        <div class="column column_copy">
          <span>© {{ currentYear }} Университет «Синергия» <br>Все права защищены</span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import CookiesPopup from '@/components/popups/cookiesPopup.vue';

export default {
  name: 'TheFooter',
  components: {
    CookiesPopup,
  },

  data: () => ({
    show: false,
  }),

  computed: {
    user() {
      return this.$store.getters.user;
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      );
    },
    phone() {
      return process.env.phone;
    },
    currentYear() {
      const year = new Date();
      return year.getFullYear();
    },
  },

  mounted() {
    if (this.$route.name === 'index') {
      if (this.isMobile) {
        window.setTimeout(() => {
          this.$store.commit('setSalePopup', false);
          this.show = true;
        }, 25 * 1000);
      } else {
        window.setTimeout(() => { this.show = true; }, 15 * 1000);
      }
    }
  },

  methods: {
    whatsappLink() {
      window.open('https://api.whatsapp.com/send?phone=74952803976', '_blank');
    },
    close() {
      this.show = false;
      this.$store.commit('setSalePopup', true);
    },
  },
};
</script>

<style scoped lang="scss">
.contacts_wspphone {
  display: inline-grid;
  align-items: center;
  justify-content: end;
  align-content: center;
  grid-auto-flow: column;
  justify-self: stretch;
  align-self: end;
  justify-items: stretch;
  column-gap: .4em;
  width: 100%;

  @include media-down(laptop) {
    margin-top: 1em;
    justify-content: flex-end;
  }
}

.whatsappLink {
  cursor: pointer;
  margin-right: auto;

  @include respond-to(desktop) {
    display: none;
  }
}

// Социальные иконки
.footer_social {
  // background: $white-color;
  height: 3em;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;
  gap: 0.7em;

  @include respond-to(mobile) {
    justify-content: flex-start;
    margin: 1em 0;
  }

  &_item {
    display: inline-block;
    height: 24px;
    width: 24px;

    @include respond-to(mobile) {
      height: 32px;
      width: 32px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}

// Социальные иконки конец

.main_footer {
  background-color: lighten($gray-color, 27%);
  padding: 32px 32px 7px 32px;
  margin: 1em auto 0 auto;
  max-width: 1200px;
  width: calc(100% - 64px);
  border-radius: 32px 32px 0 0;
  box-sizing: content-box;
  z-index: 98;

  @include media-down(laptop) {
    padding: 32px 6px 60px 6px;
    width: 95%;
    box-sizing: border-box;
  }

  @include media-down(tablet) {
    padding: 32px 16px 60px 16px;
  }
}

.footer {
  margin: 10em auto 0 auto;
  width: 100%;
  max-width: 1200px;
  border-radius: 32px 32px 0 0;

  @include media-down(tablet) {
    margin: 0 0 3em 0;
  }

  &__content {
    padding: 0;

    @include media-down(tablet) {
      padding: 0;
      display: flex;
      align-items: flex-start;
    }

    & > .column {
      align-items: flex-start;
      align-self: stretch;
      justify-content: flex-start;
      width: 38%;

      &_copy {
        display: none;
      }

      @include media-down(tablet) {
        width: 100%;
        order: 1;

        &_copy {
          order: 4;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          color: $gray-color;
          display: block;
          margin: 1em 0 0 0;
        }
      }

      &_list_user {
        width: 12%;

        @include media-down(laptop) {
          width: 100%;
        }

        &_last {
          width: 22%;

          @include media-down(laptop) {
            width: 100%;
            padding: 0;
          }
        }
      }

      &.column_margin {
        box-sizing: border-box;
        padding: 0;
        margin: 0;

        @include media-down(laptop) {
          margin: 0;
          padding: 1.5em 0 0 0;
          order: 3;
        }

        &.column_list_user_last {
          // width: 22%;

          @include media-down(laptop) {
            padding: 0;
            width: 100%;
          }
        }
      }

      &.contacts {
        align-items: flex-end;
        width: 20%;

        @include media-down(laptop) {
          width: 40%;
        }

        @include media-down(tablet) {
          align-items: flex-start;
          width: 100%;
          order: 2;
          margin: 0;
        }
      }

      @include media-down(tablet) {
        align-items: flex-start;
      }
    }

    .column_logo {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 0 0 1em 0;
      width: 100%;

      @include media-down(tablet) {
        order: 1;
      }

      & > hr {
        display: none;

        @include media-down(tablet) {
          display: block;
          width: 100%;
          margin: 0 0 20px 0;
        }
      }

      &_item {
        margin: 0;
        border-right: 2px solid #ccc;
        padding-right: 32px;
        margin-right: 32px;

        @include media-down(laptop) {
          border-right: 0;
          padding-right: 0;
          margin-right: 0;
          padding-bottom: 16px;
        }

        &__mobhide {
          border: 0;
          margin: 0;
          padding: 0;
          min-height: 47px;

          @include media-down(tablet) {
            display: none;
          }
        }
      }
    }
  }

  & > .container {
    padding: 2.5em 1em;
    border-radius: 32px 32px 0 0;

    @include media-down(tablet) {
      padding: 9em 1em 2.5em 1em;
    }
  }

  &__logo {
    margin-bottom: 1em;

    @include media-down(mobile) {
      margin-bottom: 2em;
    }
  }

  &__copyright {
    font-weight: 500;
    font-size: 0.8em;
    color: $gray-color;
    line-height: 17px;

    @include media-down(tablet) {
      display: none;
    }
  }

  &__list-item {
    padding-bottom: 1em;
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    line-height: 134%;

    @include media-down(tablet) {
      padding-bottom: 1.5em;
    }

    a {
      font-size: 16px;
      color: $black-color;

      &:hover {
        color: $purple-color;
      }
    }
  }

  &__number {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: $grey;
    white-space: nowrap;
  }

  &__email {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 134%;
    color: $purple-color;
    margin: 1em 0;
  }

  a,
  a:visited {
    color: $black-color;
  }

  @include respond-to(mobile) {
    ul {
      width: 100%;

      li {
        text-align: center;
      }
    }
  }
}

.privacy {
  a {
    color: $black-color;

    &:hover {
      color: $purple-color;
    }
  }
}

img.column_logo_item.column_logo_item__mobhide {
  margin: 0 0 0 25px;
  display: inline-block;
  height: 80px;

  &_last {
    margin: 0;
  }
}

.footer_social {
  @include media-down(tablet) {
    justify-content: flex-start;
  }

  &_link {
    margin: 0 1em 0 0;

    &:last-child {
      margin: 0;
    }

    img {
      height: 22px;
    }
  }
}
</style>
