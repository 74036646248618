<template>
  <div class="fields center">
    <div
      v-if="loginError"
      class="error-message-all"
      v-html="errorMessage "
    />

    <div @keypress.enter="submit">
      <div :class="fieldset_email_classes">
        <input
          id="login-email"
          v-model="$v.email.$model"
          placeholder="E-mail"
          type="email"
          name="email"
        >
        <div
          v-if="is_email_required_error"
          class="error-message"
          v-text="`Укажите свой Email`"
        />
        <div
          v-if="is_email_email_error"
          class="error-message"
          v-text="`Укажите правильный Email`"
        />
      </div>
      <div :class="fieldset_password_classes">
        <input
          id="login-password"
          v-model="$v.password.$model"
          placeholder="Пароль"
          type="password"
        >
        <div
          v-if="is_password_required_error"
          class="error-message"
          v-text="`Укажите свой пароль`"
        />
      </div>
      <div
        id="link_to_renew_the_password"
        class="remember"
        @click="$emit('remember')"
        v-text="`Я забыл пароль`"
      />
      <button
        id="button_sign_in"
        class="btn"
        :disabled="!valid"
        @click="submit"
        v-text="`Войти`"
      />
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';

const { required, email } = require('vuelidate/lib/validators');

/**
 * @deprecated
 */
export default {
  name: 'TheLogin',

  mixins: [
    validationMixin,
  ],

  data() {
    return {
      email: this.$store.getters.loginEmail,
      password: '',
      loginError: false,
      errorType: null,
    };
  },

  computed: {
    phone() {
      return process.env.phone;
    },

    errorMessage() {
      return this.errorType === 'Обратитесь в службу техподдержки'
        ? `Произошла техническая ошибка, обратитесь в службу техподдержки <br> по тел. ${this.phone}`
        : 'Email или пароль неверен!';
    },

    fieldset_email_classes() {
      return {
        fieldset: true,
        'has-error': this.$v.email.$error,
      };
    },

    fieldset_password_classes() {
      return {
        fieldset: true,
        'has-error': this.$v.password.$error,
      };
    },

    is_email_required_error() {
      return this.$v.email.required && this.$v.email.$error;
    },

    is_email_email_error() {
      return this.$v.email.email && this.$v.email.$error;
    },

    is_password_required_error() {
      return this.$v.password.required && this.$v.password.$error;
    },

    valid() {
      return !this.$v.$invalid;
    },
  },

  methods: {
    submit() {
      this.$emit('send');

      this.$api.login(this.email, this.password)
        .then((response) => {
          if (!response.role) {
            this.loginError = true;
            this.errorType = response.errors[0].message;
            this.$emit('error');
          } else {
            this.$emit('login', response);
          }
        })
        .catch(() => {
          this.$emit('error');
        })
        .finally(() => {
          this.$emit('done');
        });
    },
  },

  validations: {
    password: {
      required,
    },
    email: {
      required,
      email,
    },
  },
};

</script>

<style scoped lang="scss">
.btn {
  font-size: 20px;
}

input[type='text'],
input[type='email'],
input[type='password'] {
  border-radius: 1em;
  margin: 1em 0;
  display: block;
  width: 100%;
  outline: none;
  box-sizing: border-box;
  padding: .7em 1em;
  font-size: 20px;
  height: 60px;
}

.remember {
  text-align: center;
  font-size: 1em;
  color: $violet2-color;
  margin-bottom: 1.8em;
  cursor: pointer;
  text-decoration: none;
}

.btn {
  width: 100%;
  height: 60px;

}

.error-message-all {
  margin-top: 1em;
}

.error-message-all,
.error-message {
  font-size: 0.8em;
  color: #eea2a2;
}

.error-message {
  position: absolute;
  top: 0;
  padding: 1em;
  background-color: $white-color;
  border-radius: 10px;
  right: 0;
  transform: translateY(calc(-100% - 10px)) translateX(70%);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
  width: 15em;
  text-align: left;
  transition: 500ms;

  @include media-down(tablet) {
    right: 10em;
  }

  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 13px 13px 0 13px;
    border-color: $white-color transparent transparent transparent;
    bottom: 0;
    left: calc(20% - 1em);
    transform: translateY(calc(100% - 1px));

    @include media-down(tablet) {
      left: calc(80% - 1em);
    }
  }
}

.has-error input:hover + .error-message.error,
.has-error input:focus + .error-message {
  opacity: 1;
}

.fieldset.has-error {
  position: relative;

  &::after {
    position: absolute;
    content: url("/img/error.svg");
    display: block;
    right: 0.8em;
    top: 50%;
    transform: translateY(calc(-50% + 1px));

    &:hover .message {
      cursor: pointer;
    }
  }

}

.fields {
  animation-name: fadeInLeft;
  animation-duration: .1s;
  animation-fill-mode: both;
}

</style>
