<template>
  <div class="register_block">
    <div>
      <div
        class="title"
        v-html="title"
      />
      <div
        class="subtitle"
        v-html="subtitle"
      />
      <img
        v-if="isSendingSuccess"
        class="nosorogcall"
        src="/img/nosorogcall.svg"
        alt="nosorogcall"
      >
      <div
        v-if="!isSendingSuccess"
        class="fields center"
      >
        <name-registration-field
          ref="nameInput"
          v-model="name"
        />

        <div
          class="phone"
          :class="fieldset_phone_classes"
        >
          <phone-mask-input
            ref="phoneInput"
            v-model="$v.phone.$model"
            :class="{ 'has-error': phone && $v.$dirty && !$refs.phoneInput.isValid }"
            placeholder="Номер телефона"
            wrapper-class="fieldset fieldset__phone"
            input-class="fieldset__input_tel"
            :default-country="phoneDefCoutry"
            @onFocus="setPhoneSettings"
          />

          <div
            v-if="!phone && $v.$dirty"
            class="error-message"
            v-text="`Укажите свой номер телефона`"
          />

          <div
            v-if="phone && $v.$dirty && !$refs.phoneInput.isValid"
            class="error-message"
            v-text="`Укажите правильный номер телефона`"
          />
        </div>

        <div
          class="agree"
          @click="agree = !agree"
        >
          <div
            class="checkbox"
            :class="{checked:agree}"
          />
          <div class="label">
            Принимаю
            <nuxt-link
              to="/oferta"
              @click.native="$store.commit('showRegisterForm', true);$emit('close')"
            >
              условия соглашения
            </nuxt-link>
            <br class="hidemobile">
            и
            <nuxt-link
              to="/privacy"
              @click.native="$store.commit('showRegisterForm', true);$emit('close')"
            >
              политики конфиденциальности
            </nuxt-link>
          </div>
        </div>

        <button
          class="btn"
          :disabled="submitDisable"
          @click="submit"
        >
          Записаться
        </button>
      </div>
    </div>

    <the-spinner :value="sending" />
  </div>
</template>

<script>
import PhoneMaskInput from '@/components/common/PhoneInput/PhoneInput.vue';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import NameRegistrationField from '@/components/common/NameRegistrationField.vue';
import TheSpinner from '@/components/common/theSpinner.vue';

import pagesLand from '@/components/v2/pagesLand';
import { getQuestionnairePath } from '@/utils/questionnairePath';

/**
 * @deprecated
 */
export default {
  name: 'LidForm',

  components: {
    PhoneMaskInput,
    NameRegistrationField,
    TheSpinner,
  },

  mixins: [validationMixin],
  data() {
    return {
      name: '',
      phone: '',
      phoneDefCoutry: null,
      agree: true,
      sending: false,
      title: "Запишись на бесплатную <br class='hidemobile'>консультацию",
      subtitle: "Наш специалист свяжется <br class='hidemobile'> и ответит на любые вопросы",
      isSendingSuccess: false,
    };
  },
  validations: {
    phone: {
      required,
    },
  },
  computed: {
    submitDisable() {
      return !this.name
        || !this.$refs.nameInput.isValid
        || !this.phone
        || !this.$refs.phoneInput.isValid
        || !this.agree;
    },
    fieldset_phone_classes() {
      return {
        fieldset: true,
        'has-error': this.$v.phone.$error,
      };
    },
    is_phone_required_error() {
      return !this.$v.phone.required && this.$v.phone.$error;
    },
    is_phone_max_min_length() {
      return !this.$v.phone.minLength && this.$v.phone.$error;
    },
  },
  methods: {
    setPhoneSettings() {
      if (!this.$refs.phoneInput.$el.children[0]?.value) {
        this.phone = '+';
        this.$refs.phoneInput.$el.children[0].value = this.phone;
        this.phoneDefCoutry = 'ru';
      }
    },

    submit() {
      this.$v.$touch();

      this.sending = true;
      const payload = {
        name: this.name,
        phone: this.phone,
        landCode: this.landCode || pagesLand[this.$route.name] || pagesLand[this.$route.path],
      };
      this.$api.sendLead(payload)
        .then((sendLead) => {
          if (sendLead?.success) {
            this.title = 'Мы скоро позвоним!';
            this.subtitle = 'С вами свяжется наш консультант, <br class="hidemobile">ожидайте звонка!';
            this.isSendingSuccess = true;
            this.sending = false;
            this.$vkPixel.reachGoal('Lead');

            this.$router.push(getQuestionnairePath(this.$route));
          } else {
            // eslint-disable-next-line no-alert
            alert('Произошла ошибка при отправке данных');
          }
        })
        .finally(() => {
          this.sending = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.nosorogcall {
  margin: 0 auto;
  display: block;
}

.form {
  padding: 2em;
  border: 3px solid $orange-color;
  border-radius: 32px;
  background-color: $white-color;
  max-width: 465px;
  width: 100%;
  position: relative;

  .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 134%;
    text-align: center;
    color: $grey;

    @media screen and (max-width: 630px) {
      margin-top: 24px;
    }
  }

  .subtitle {
    font-size: 16px;
    line-height: 134%;
    text-align: center;
    color: $grey-select;
    margin: 1em 0;
  }
}

.fields {
  display: grid;
  gap: 20px;
  margin-top: 2em;

  input {
    width: 100%;
    height: 60px;
  }

  .phone .fieldset__phone {
    margin-top: 0;
  }
}

.agree {
  display: flex;
  margin-bottom: 8px;
  font-weight: 600;

  .checkbox {
    width: 20px;
    height: 20px;
    border: 1px solid $gray-color;
    box-sizing: border-box;
    border-radius: 6px;
    position: relative;

    &.checked:after {
      content: '';
      position: absolute;
      top: 4px;
      left: 3px;
      width: 11px;
      height: 7px;
      border-bottom: 3px solid $orange-color;
      border-left: 3px solid $orange-color;
      border-radius: 3px;
      transform: rotate(-45deg);
    }
  }

  .label {
    color: $gray-color;
    margin-left: 1em;
    display: inline-block;
    font-size: 12px;
    flex-shrink: 1;
    cursor: pointer;

    span {
      color: $orange-color;
      border-bottom: 1px solid $orange-color;
    }
  }

  a,
  a:visited {
    color: $orange-color;
  }
}

.btn {
  width: 100%;
  height: 60px;
  box-shadow: 0 12px 32px rgba(255, 153, 0, 0.32);

  &.disabled,
  &:disabled {
    box-shadow: none;
  }
}

.error-message-all,
.error-message {
  font-size: 0.8em;
  color: #eea2a2;
}

.error-message {
  position: absolute;
  top: 0;
  padding: 1em;
  background-color: $white-color;
  border-radius: 10px;
  right: 0;
  transform: translateY(calc(-100% - 10px)) translateX(70%);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
  width: 15em;
  text-align: left;
  transition: 500ms;

  @include respond-to(allmobile) {
    right: 10em;
  }

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 13px 13px 0 13px;
    border-color: $white-color transparent transparent transparent;
    bottom: 0;
    left: calc(20% - 1em);
    transform: translateY(calc(100% - 1px));

    @include respond-to(allmobile) {
      left: calc(80% - 1em);
    }
  }
}

.has-error input:hover + .error-message.error,
.has-error input:focus + .error-message {
  opacity: 1;
}

.fieldset.has-error {
  position: relative;

  &::after {
    position: absolute;
    content: url('/img/error.svg');
    display: block;
    right: 0.8em;
    top: 50%;
    transform: translateY(calc(-50% + 1px));

    &:hover .message {
      cursor: pointer;
    }
  }
}
</style>
