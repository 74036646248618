<template>
  <div class="register_block">
    <div class="title">
      Продолжить знакомство <br class="hidemobile">со школой
    </div>
    <div class="subtitle">
      Получите бесплатную консультацию специалиста, доступ в личный кабинет,
      скидку на обучение и подарок
    </div>
    <div class="fields center">
      <name-registration-field
        v-model="name"
      />

      <div :class="fieldset_phone_classes">
        <phone-mask-input
          ref="phoneInput"
          v-model="phone"
          :class="{ 'has-error': phone && $v.$dirty && !$refs.phoneInput.isValid }"
          placeholder="Номер телефона"
          wrapper-class="fieldset fieldset__phone"
          input-class="fieldset__input_tel"
          :default-country="phoneDefCoutry"
          @input="clearSubmitError('phone')"
          @onFocus="setPhoneSettings"
        />

        <div
          v-if="!phone && $v.$dirty"
          class="error-message"
          v-text="`Укажите свой номер телефона`"
        />

        <div
          v-if="phone && $v.$dirty && !$refs.phoneInput.isValid"
          class="error-message"
          v-text="`Укажите правильный номер телефона`"
        />

        <div
          v-if="!$v.phone.unUsed"
          class="error-message"
          v-text="`Такой телефон уже найден`"
        />
      </div>

      <div :class="fieldset_email_classes">
        <input
          v-model="email"
          placeholder="E-mail"
          type="email"
          @focus="submitError.email = null"
          @keydown.space="skipSpace"
          @keyup="clearSubmitError('email')"
        >
        <div
          v-if="is_email_required_error"
          class="error-message"
          v-text="`Укажите свой Email`"
        />
        <div
          v-if="is_email_email_error"
          class="error-message"
          v-text="`Укажите правильный Email`"
        />
        <div
          v-if="!$v.email.unUsed"
          class="error-message"
          v-text="`Такой Email уже найден`"
        />
      </div>
      <div
        class="agree"
        @click="agree = !agree"
      >
        <div
          id="policy_checkbox"
          :class="['checkbox', { checked: agree }]"
        />
        <div class="label">
          Принимаю
          <nuxt-link
            to="/oferta"
            @click.native="
              $store.commit('showRegisterForm', true);
              $emit('close');
            "
          >
            условия соглашения
          </nuxt-link>
          <br class="hidemobile">
          и
          <nuxt-link
            to="/privacy"
            @click.native="
              $store.commit('showRegisterForm', true);
              $emit('close');
            "
          >
            политики конфиденциальности
          </nuxt-link>
        </div>
      </div>
      <div
        class="agree"
        @click="agreeNotifications = !agreeNotifications"
      >
        <div
          id="notification_checkbox"
          :class="['checkbox', { checked: agreeNotifications }]"
        />
        <div class="label">
          Получать уведомления о важных событиях по E-mail
        </div>
      </div>
      <button
        id="register_registration_button"
        class="btn"
        :disabled="!valid"
        @click="submit"
        v-text="`Зарегистрироваться`"
      />
    </div>
  </div>
</template>

<script>
import PhoneMaskInput from '@/components/common/PhoneInput/PhoneInput.vue';
import Register from '@/mixins/register';
import NameRegistrationField from '../common/NameRegistrationField.vue';

/**
 * @deprecated
 */
export default {
  name: 'TheRegister',
  components: {
    NameRegistrationField,
    PhoneMaskInput,
  },
  mixins: [Register],

  data: () => ({
    name: '',
    phoneDefCoutry: '',
    landCode: 'school_synergy_ru',
  }),
  computed: {
    fieldset_phone_classes() {
      return {
        fieldset: true,
        'has-error': this.$v.phone.$error,
      };
    },

    fieldset_email_classes() {
      return {
        fieldset: true,
        'has-error': this.$v.email.$error,
      };
    },

    is_name_required_error() {
      return !this.$v.name.required && this.$v.name.$error;
    },

    is_name_max_error() {
      return !this.$v.name.max && this.$v.name.$error;
    },

    is_phone_required_error() {
      return !this.$v.phone.required && this.$v.phone.$error;
    },

    is_phone_max_min_length() {
      return !this.$v.phone.minLength && this.$v.phone.$error;
    },

    is_email_required_error() {
      return !this.$v.email.required && this.$v.email.$error;
    },

    is_email_email_error() {
      return !this.$v.email.email && this.$v.email.$error;
    },
  },
  methods: {
    setPhoneSettings() {
      if (!this.$refs.phoneInput.$el.children[0].value) {
        this.phone = '+';
        this.$refs.phoneInput.$el.children[0].value = this.phone;
        this.phoneDefCoutry = 'ru';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.popup-form .agree .checkbox.checked:after {
  border-bottom: 3px solid $purple-color;
  border-left: 3px solid $purple-color;
}

.v-select {
  margin: 1em 0;
}

.popup-form .agree {
  .checkbox {
    min-width: 20px;

    &.checked:after {
      top: 44%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

.hidemobile {
  @include respond-to(allmobile) {
    display: none;
  }
}

.subtitle {
  font-size: 16px;
  line-height: 134%;
  text-align: center;
  color: $grey-select;
}

.title {
  font-size: 24px;
  line-height: 134%;
  text-align: center;
  color: $grey;
}

.btn {
  font-size: 20px;
}

input[type='text'],
input[type='email'],
input[type='tel'],
input[type='password'] {
  display: block;

  margin: 1em 0;
  padding: 0.7em 1em;

  width: 100%;
  height: 60px;

  border-radius: 1em;
  outline: none;

  box-sizing: border-box;

  font-size: 20px;
}

.agree {
  display: flex;
  margin-bottom: 2em;

  .checkbox {
    width: 20px;
    height: 20px;
    border: 1px solid $gray-color;
    box-sizing: border-box;
    border-radius: 6px;
    position: relative;

    &.checked:after {
      content: '';
      position: absolute;
      top: 3px;
      left: 3px;
      width: 11px;
      height: 7px;
      border-bottom: 3px solid $purple-color;
      border-left: 3px solid $purple-color;
      border-radius: 3px;
      transform: rotate(-45deg);
    }
  }

  .label {
    color: $gray-color;
    margin-left: 1em;
    display: inline-block;
    font-size: 12px;
    flex-shrink: 1;
    cursor: pointer;

    span {
      color: $purple-color;
      border-bottom: 1px solid $purple-color;
    }
  }

  a,
  a:visited {
    color: $purple-color;
  }
}

.title {
  font-size: 1.4em;
  font-weight: 600;
  text-align: center;
  margin-top: 1.5em;
}

.subtitle {
  text-align: center;
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 0.95em;
  line-height: 134%;
  color: $gray-color;
}

button.btn.fill {
  display: block;
  width: 100%;
}

.register-done {
  padding: 1em;
  text-align: center;

  .title {
    margin-bottom: 1em;
  }

  p {
    color: $gray-color;
    font-size: 16px;
    line-height: 134%;
    text-align: center;
    margin-bottom: 2em;
  }
}

.btn {
  width: 100%;
  height: 60px;
}

.error-message-all,
.error-message {
  font-size: 0.8em;
  color: #eea2a2;
}

.error-message {
  position: absolute;
  top: 0;
  padding: 1em;
  background-color: $white-color;
  border-radius: 10px;
  /*right: 20px;*/
  left: 81%;
  transform: translateY(calc(-100% - 10px));
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
  max-width: 20em;
  width: fit-content;
  text-align: left;
  transition: 500ms;

  @include respond-to(allmobile) {
    left: auto;
    right: 0;
  }

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 13px 13px 0;
    border-color: $white-color transparent transparent;
    bottom: 0;
    left: 31px;
    transform: translateY(calc(100% - 1px));

    @include respond-to(allmobile) {
      left: auto;
      right: 10px;
    }
  }
}

.has-error input:hover + .error-message.error,
.has-error input:focus + .error-message {
  opacity: 1;
}

.fieldset.has-error {
  position: relative;

  &::after {
    position: absolute;
    content: url('/img/error.svg');
    display: block;
    right: 0.8em;
    top: 50%;
    transform: translateY(calc(-50% + 1px));

    &:hover .message {
      cursor: pointer;
    }
  }
}

.register_block {
  animation-name: fadeInRight;
  animation-duration: 0.1s;
  animation-fill-mode: both;
}
</style>

<style lang="scss">
.fieldset {
  position: relative;

  &__phone {
    margin: 1em 0 0;
  }

  &__input_tel {
    width: 100% !important;
    height: 60px !important;
  }
}
</style>
